<template>
  <section>
    <panel title="Credit Data Log">
      <data-table-local
        :columns="_columns"
        :items="items"
        :viewAble="false"
        :newAble="false"
        :deleteAble="false"
        :editAble="false"
      />
    </panel>
  </section>
</template>
<script>
export default {
  async mounted() {
    try {
      this.items = await this.api.get(`/api/logData/creditTransaction`)
    } catch (error) {
      console.error(error)
      return ""
    }
  },
  data() {
    return {
      items: "",
    }
  },
  computed: {
    _columns() {
      return [
        {
          label: "Status",
          field: "status_data.name_th",
          // tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "Credit Balance",
          field: "credit_balance",
          thClass: "text-center",
          tdClass: "text-right",
          type: "string",
          sortable: false,
        },
        {
          label: "Credit Amount",
          field: "credit_amount",
          tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
          // type: "number",
        },
        {
          label: "Credit Total",
          field: "credit_total",
          tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
        },

        {
          label: "Timestamp",
          field: "timestamp",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
      ]
    },
  },
}
</script>
